<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div class="uk-form-stacked">
        <h4 class="uk-heading-line">
          Facility Info
        </h4>
        <div
          v-if="loadingData"
          class="uk-flex uk-flex-center uk-flex-middle"
        >
          <div uk-spinner />
        </div>
        <div
          v-else
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  VCP Code
                </label>
                <div class="uk-form-controls uk-flex">
                  <div class="uk-width-1-2 uk-margin-right">
                    <input
                      v-model="formPulping.evc"
                      v-validate="'required'"
                      name="evc"
                      class="uk-input"
                      type="text"
                      autocomplete="off"
                      placeholder="Please Select Export Hub"
                      disabled
                      :class="{'uk-form-danger': errors.has('evc')}"
                    >
                  </div>
                  <div class="uk-width-1-2">
                    <input
                      v-model="formPulping.pc"
                      v-validate="'required|max:50'"
                      name="pc"
                      class="uk-input"
                      type="text"
                      placeholder="Enter VCP Code"
                      :class="{'uk-form-danger': errors.has('pc')}"
                    >
                    <span
                      v-show="errors.has('pc')"
                      class="uk-text-small uk-text-danger"
                    >{{ 'Required' }}</span>
                  </div>
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Export Hub
                </label>
                <div class="uk-form-controls">
                  <multiselect 
                    v-model="detailPulping.export_hub" 
                    v-validate="'required'"
                    label="name" 
                    name="export_hub" 
                    track-by="id" 
                    placeholder="Select Export Hub or Type to Search" 
                    open-direction="bottom" 
                    :options="listExportHub.data"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                    @select="handleSelectExportHub"
                    @search-change="(input) => handleSearchDropdown('export', listExportHub.data, input)"
                    @remove="() => recallApiList('export')"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
                <span
                  v-show="errors.has('export_hub')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('export_hub') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Latitude
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPulping.latitude"
                    v-validate="'required|max:100'"
                    name="latitude"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Latitude"
                    :class="{'uk-form-danger': errors.has('latitude')}"
                  >
                </div>
                <span
                  v-show="errors.has('latitude')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('latitude') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Address
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPulping.address"
                    v-validate="'required|max:100'"
                    name="address"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Address"
                    :class="{'uk-form-danger': errors.has('address')}"
                  >
                </div>
                <span
                  v-show="errors.has('address')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('address') }}</span>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Facility Name
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPulping.facility_name"
                    v-validate="'required|max:50'"
                    name="facility_name"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Facility Name"
                    :class="{'uk-form-danger': errors.has('facility_name')}"
                  >
                </div>
                <span
                  v-show="errors.has('facility_name')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('facility_name') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Hulling Facility
                </label>
                <div class="uk-form-controls">
                  <multiselect 
                    v-model="detailPulping.hulling_facility"
                    v-validate="'required'"
                    label="facility_name"
                    name="hulling_facility"
                    track-by="id"
                    :placeholder="detailPulping.export_hub ? 'Select Hulling Facility or Type to Search' : 'Select Export Hub First'"
                    open-direction="bottom"
                    :options="listHulling.data"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                    :disabled="!detailPulping.export_hub"
                    @select="handleSelectHulling"
                    @search-change="(input) => handleSearchDropdown('hulling', listHulling.data, input)"
                    @remove="() => recallApiList('hulling')"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
                <span
                  v-show="errors.has('hulling_facility')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('hulling_facility') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Longitude
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPulping.longitude"
                    v-validate="'required|max:100'"
                    name="longitude"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Longitude"
                    :class="{'uk-form-danger': errors.has('longitude')}"
                  >
                </div>
                <span
                  v-show="errors.has('longitude')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('longitude') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Status
                </label>
                <div class="uk-form-controls">
                  <label class="switch">
                    <input
                      v-if="formPulping.status == 1"
                      class="switcher"
                      name="status"
                      type="checkbox"
                      checked
                      @change="handleStatus"
                    >
                    <input
                      v-else
                      class="switcher"
                      name="status"
                      type="checkbox"
                      @change="handleStatus"
                    >
                    <span class="slider round" />
                  </label>
                  <span :class="formPulping.status == 1 ? 'label-switcher active ' : 'label-switcher'">{{ formPulping.status == 1 ? status.ACTIVE : status.INACTIVE }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        Lapang Jemur
      </h4>
      <div
        v-if="loadingData"
        class="uk-flex uk-flex-center uk-flex-middle"
      >
        <div uk-spinner />
      </div>
      <div
        v-else
        class="uk-form-stacked form-dry-fields"
      >
        <div
          v-for="(data, index) in drying_field_list"
          :key="index"
          class="uk-child-width-expand@s uk-grid-small" 
          uk-grid
        >
          <div class="uk-width-1-2">
            <div class="uk-form-controls uk-position-relative withlabel">
              <label class="uk-form-label">
                Type {{ index + 1 }}
              </label>
              <select
                v-model="data.drying_field_type_id"
                class="uk-width-expand custom-select"
                name="drying_field_type_id"
              >
                <option
                  value=""
                  hidden
                >
                  Select Type
                </option>
                <option
                  v-for="(item, key) in listDryingField"
                  :key="key"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <img
                :src="`${images}/icon/ic_chevron_down.svg`"
                alt="ic_chevron"
              >
            </div>
            <span
              v-show="errors.has(`drying_field_type_${index + 1}`)"
              class="uk-text-small uk-text-danger"
            >{{ errors.first(`drying_field_type_${index + 1}`) }}</span>
          </div>

          <div class="uk-width-1-2">
            <label class="uk-form-label">
              Name {{ index + 1 }}
            </label>
            <div class="uk-form-controls">
              <input
                v-model="data.drying_field_name"
                :name="`drying_field_name_${index + 1}`"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Lapang Jemur Name"
                :class="{'uk-form-danger': errors.has(`drying_field_name_${index + 1}`)}"
              >
              <span
                v-show="errors.has(`drying_field_name_${index + 1}`)"
                class="uk-text-small uk-text-danger"
              >{{ errors.first(`drying_field_name_${index + 1}`) }}</span>
            </div>
          </div>

          <div
            class="img-fields"
            :hidden="index > 0 ? false : true"
          >
            <img
              :src="`${images}/icon/ic_delete_table.svg`"
              alt=""
              @click.prevent="handleDeleteFormDrying()"
            >
          </div>
        </div>
      </div>

      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
        uk-grid
      >
        <button
          class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
          style="justify-content: flex-start;"
          @click.prevent="handleCreateFormDrying()"
        >
          <img
            :src="`${images}/icon/ic_plus_green.svg`"
            class="uk-margin-small-right cursor-pointer"
          >
          Add Lapang Jemur
        </button>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        Info Bak
      </h4>
      <div
        v-if="loadingData"
        class="uk-flex uk-flex-center uk-flex-middle"
      >
        <div uk-spinner />
      </div>
      <div
        v-else
        class="uk-form-stacked form-bucket-list"
      >
        <div
          v-for="(data, index) in bucket_list"
          :key="index"
          class="uk-child-width-expand@s uk-grid-small" 
          uk-grid
        >
          <div class="uk-width-1-1">
            <label class="uk-form-label">
              Bak Name {{ index + 1 }}
            </label>
            <div class="uk-form-controls">
              <input
                v-model="data.bucket_name"
                :name="`bucket_name_${index + 1}`"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Bak Name"
                :class="{'uk-form-danger': errors.has(`bucket_name_${index + 1}`)}"
              >
              <span
                v-show="errors.has(`bucket_name_${index + 1}`)"
                class="uk-text-small uk-text-danger"
              >{{ errors.first(`bucket_name_${index + 1}`) }}</span>
            </div>
          </div>

          <div
            class="uk-width-auto img-fields"
            :hidden="index > 0 ? false : true"
          >
            <img
              :src="`${images}/icon/ic_delete_table.svg`"
              alt=""
              @click.prevent="handleDeleteFormBucket()"
            >
          </div>
        </div>
      </div>
      
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
        uk-grid
      >
        <button
          class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
          style="justify-content: flex-start;"
          @click.prevent="handleCreateFormBucket()"
        >
          <img
            :src="`${images}/icon/ic_plus_green.svg`"
            class="uk-margin-small-right cursor-pointer"
          >
          Add Bak
        </button>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        App Access
      </h4>
      <div class="uk-margin-top uk-child-width-expand@s">
        <div class="uk-overflow-auto border-table">
          <table
            class="uk-table uk-table-divider uk-table-middle"
            aria-describedby="hulling-facility-table"
          >
            <thead class="thead-table-varion">
              <tr>
                <th class="uk-table-expand">
                  Menu Name
                </th>
                <th class="uk-width-small" />
              </tr>
            </thead>
            <template v-if="loadingData">
              <loading-table :colspan="10" />
            </template>
            <template v-else-if="Object.keys(detailPulping?.pulping_app_access).length > 0">
              <tbody>
                <tr>
                  <td>Terima Gabah</td>
                  <td>
                    <label class="switch">
                      <input
                        class="switcher"
                        type="checkbox"
                        :checked="formPulping.recipient"
                        @change="handleChangeRecipient"
                      >
                      <span class="slider round" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>Sortasi</td>
                  <td>
                    <label class="switch">
                      <input
                        class="switcher"
                        type="checkbox"
                        :checked="formPulping.sorter"
                        @change="handleChangeSorter"
                      >
                      <span class="slider round" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>Pengupasan</td>
                  <td>
                    <label class="switch">
                      <input
                        class="switcher"
                        type="checkbox"
                        :checked="formPulping.peeler"
                        @change="handleChangePeeler"
                      >
                      <span class="slider round" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>Fermentasi</td>
                  <td>
                    <label class="switch">
                      <input
                        class="switcher"
                        type="checkbox"
                        :checked="formPulping.fermenter"
                        @change="handleChangeFermenter"
                      >
                      <span class="slider round" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>Pencucian</td>
                  <td>
                    <label class="switch">
                      <input
                        class="switcher"
                        type="checkbox"
                        :checked="formPulping.washer"
                        @change="handleChangeWasher"
                      >
                      <span class="slider round" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>Pengeringan</td>
                  <td>
                    <label class="switch">
                      <input
                        class="switcher"
                        type="checkbox"
                        :checked="formPulping.dryer"
                        @change="handleChangeDryer"
                      >
                      <span class="slider round" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>Pengepakan</td>
                  <td>
                    <label class="switch">
                      <input
                        class="switcher"
                        type="checkbox"
                        :checked="formPulping.courier"
                        @change="handleChangeCourier"
                      >
                      <span class="slider round" />
                    </label>
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-else>
              <empty-table
                :colspan="10"
              />
            </template>
          </table>
        </div>
      </div>
    
      <div class="uk-flex uk-flex-right uk-margin-medium-top">
        <button
          class="uk-button soft-green uk-margin-right"
          @click="showCancelConfirmModal"
        >
          Cancel
        </button>
        <button
          class="uk-button green"
          :class="isCanAccessUser('edit', 'PULPING FACILITY') ? '' : 'disabled'"
          :disabled="!isCanAccessUser('edit', 'PULPING FACILITY')"
          @click="showSaveConfirmModal"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { STATUS } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    EmptyTable,
    LoadingTable
  },
  props: {
    id: {
      required: true,
      type: String
    },
    images: {
      required: true,
      type: String
    },
    loadingData:{
      type: Boolean
    },
    listDryingField: {
      required: true,
      type: Array
    },
    listExportHub: {
      required: true,
      type: Object
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      status: STATUS,
      drying_field_list: [],
      bucket_list: [],
      formPulping: {},
      onSearch: false,
      exportHubId: '',
      hulling_facility_code: ''
    }
  },
  computed: {
    ...mapGetters({
      detailPulping: 'pulpingFacility/detail_pulping',
      listHulling: 'hullingFacility/list_hulling'
    })
  },
  watch: {
    async detailPulping(val) {
      if(val) {
        this.formPulping = {
          vcp_code: val?.vcp_code || '',
          evc: this.getVc(val?.vcp_code),
          pc: this.getPc(val?.vcp_code),
          facility_name: val.facility_name || '',
          latitude: val.latitude || '',
          longitude: val.longitude || '',
          export_hub_id: val.export_hub_id || '',
          hulling_facility_id: val.hulling_facility_id || '',
          address: val.address || '',
          recipient: val.pulping_app_access?.recipient || false,
          sorter: val.pulping_app_access?.sorter || false,
          peeler: val.pulping_app_access?.peeler || false,
          fermenter: val.pulping_app_access?.fermenter || false,
          washer: val.pulping_app_access?.washer || false,
          dryer: val.pulping_app_access?.dryer || false,
          courier: val.pulping_app_access?.courier || false,
          status: val.status || null,
          drying_field_list: this.drying_field_list,
          bucket_list: this.bucket_list
        },
        this.bucket_list = val?.pulping_bucket
        this.drying_field_list = val?.pulping_drying_field
        this.exportHubId = val?.export_hub_id
        await this.actionGetListHulling({ export_hub_id: this.exportHubId, page: 1, limit: 50 })
      }
    }
  },
  methods: {
    ...mapActions({
      actionGetListHulling: 'hullingFacility/getListHulling',
      actionGetListExportHub: 'exportHub/getListExportHub'
    }),
    getVc(vcp_code){
      return vcp_code.split('-').slice(0, 2).join('-')
    },
    getPc(vcp_code) {
      return vcp_code.split('-').slice(2, 3).join('-')
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    async handleSearchDropdown(list, data, input) {
      const dataList = data
  
      const checker = dataList.some((item) => {
        const lowerFacilityName = item.facility_name ? item.facility_name.toLowerCase() : item.name.toLowerCase()
        const lowerSearchQuery = input.toLowerCase()
        return lowerFacilityName.includes(lowerSearchQuery)
      })

      if (!checker && !(dataList.length < 1)) {
        this.onSearch = true
        switch (list) {
        case 'hulling':
          await this.actionGetListHulling({ export_hub_id: this.exportHubId,  search: input })
          break
        default:
          await this.actionGetListExportHub({ search: input })
          break
        }
      }

      if (!input) {
        let i = input
        if(!(i.includes(input)) || dataList.length < 1) {
          switch (list) {
          case 'hulling':
            await this.actionGetListHulling({ export_hub_id: this.exportHubId, page: 1, limit: 50 })
            break
          default:
            await this.actionGetListExportHub({ page: 1, limit: 50 })
            break
          }
        }
      }
    },
    async recallApiList(list){
      if(this.onSearch){
        this.onSearch = false
        switch (list) {
        case 'hulling':
          await this.actionGetListHulling({ export_hub_id: this.exportHubId, page: 1, limit: 50 })
          break
        default:
          await this.actionGetListExportHub({ export_hub_id: this.exportHubId, page: 1, limit: 50 })
          break
        }
      }
    },
    handleCreateFormDrying() {
      this.drying_field_list.push({
        id: '',
        drying_field_type_id: '',
        drying_field_name: '',
        pulping_id: this.id
      })
    },
    handleDeleteFormDrying() {
      this.drying_field_list.splice(this.drying_field_list.length - 1, 1)
    },
    handleCreateFormBucket() {
      this.bucket_list.push({
        id: '',
        bucket_name: '',
        pulping_id: this.id
      })
    },
    handleDeleteFormBucket() {
      this.bucket_list.splice(this.bucket_list.length - 1, 1)
    },
    async handleSelectExportHub(val) {
      this.exportHubId = val.id
      this.formPulping.pc = this.getPc(this.formPulping.vcp_code)
      this.formPulping.vcp_code = ''
      this.formPulping.evc = ''
      await this.recallApiList('export')
      this.detailPulping.hulling_facility = ''
      await this.actionGetListHulling({ export_hub_id: this.exportHubId, page: 1, limit: 50 })
      this.formPulping.evc = val?.evc_code
      this.formPulping.export_hub_id = val?.id
    },
    async handleSelectHulling(val) {
      if(this.formPulping.evc.includes(val.vc_code )){
        this.formPulping.evc = val.vc_code
      } else {
        this.formPulping.pc = this.getPc(this.detailPulping.vcp_code)
        this.formPulping.evc = ''
        this.formPulping.evc = val.vc_code
      }
      await this.recallApiList('hulling')
      this.formPulping.hulling_facility_id = val?.id
    },
    handleChangeRecipient(event) {
      this.formPulping.recipient = event.target.checked ? true : false
    },
    handleChangeSorter(event) {
      this.formPulping.sorter = event.target.checked ? true : false
    },
    handleChangePeeler(event) {
      this.formPulping.peeler = event.target.checked ? true : false
    },
    handleChangeFermenter(event) {
      this.formPulping.fermenter = event.target.checked ? true : false
    },
    handleChangeWasher(event) {
      this.formPulping.washer = event.target.checked ? true : false
    },
    handleChangeDryer(event) {
      this.formPulping.dryer = event.target.checked ? true : false
    },
    handleChangeCourier(event) {
      this.formPulping.courier = event.target.checked ? true : false
    },
    handleStatus(event) {
      this.formPulping.status = event.target.checked ? 1 : 0
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'PulpingFacility',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      let tempDrying = []
      let tempBucket = []
      this.drying_field_list.map(item => tempDrying.push({
        id: item.id,
        drying_field_type_id: item.drying_field_type_id || '',
        drying_field_name: item.drying_field_name,
        pulping_id: this.id
      }))
      this.bucket_list.map(item => tempBucket.push({
        id: item.id,
        bucket_name: item.bucket_name,
        pulping_id: this.id
      }))
      this.formPulping.vcp_code = this.formPulping.evc+'-'+this.formPulping.pc
      this.formPulping.drying_field_list = tempDrying
      this.formPulping.bucket_list = tempBucket
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formPulping)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .form-dry-fields .uk-width-1-2 {
    max-width: 48%;
  }
  .form-bucket-list .uk-width-1-1 {
    max-width: 96%;
  }
  .btn-add-create-form {
    font-family: 'interMedium';
    font-weight: 600;
    font-size: 16px;
    background: transparent;
    color: #2FAC4A;
  }
  .img-fields {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;
  }
  .uk-table thead tr {
    border: 1px solid #E0E0E0;
  }
  .uk-table tbody tr {
    border: 1px solid #E0E0E0;
  }
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
</style>
